import { AgGridReact } from 'ag-grid-react';
import { AgGridReactProps, AgReactUiProps } from 'ag-grid-react/lib/shared/interfaces';
import { FC } from 'react';

import { useLingui } from '@lingui/react';

import { agGridLocaleProps } from 'src/utils/locale';

type Props = AgGridReactProps | AgReactUiProps;

// Wrapper component for AgGridReact with localization
export const AgGrid: FC<Props> = (props: Props) => {
    const { i18n } = useLingui();

    const getContextMenuItems = (params) => [
        'copy',
        'copyWithHeaders',
        'paste',
        'separator',
        {
            name: 'Excel Export',
            action: () => {
                params.api.exportDataAsExcel({
                    columnKeys: params.api.columnModel.gridColumns
                        .map((col) => col.colId)
                        .filter((col) => col !== 'actions'),
                    processCellCallback: (callbackParams) => {
                        const { column, value } = callbackParams;

                        if (column.getColId() === 'owner') {
                            const fullName = `${value.firstName} ${value.lastName}`;
                            return `${value || fullName}`;
                        }

                        if (column.getColId() === 'consultants') {
                            return value.map((consultant) => consultant.companyName).join(', ');
                        }

                        if (column.getColId() === 'consultingFirms') {
                            return value.map((consultant) => consultant.companyName).join(', ');
                        }

                        return value;
                    },
                });
            },
        },
    ];

    return (
        <AgGridReact
            // reactUi={!!true}
            suppressFieldDotNotation={!!true}
            getContextMenuItems={getContextMenuItems}
            {...agGridLocaleProps(i18n)}
            {...props}
        />
    );
};
