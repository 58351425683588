/**
 * @generated SignedSource<<ac4c178354f3aa1c644dae985da2e993>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QuoteProposalProviderQuery$variables = {
  quoteProposalId: string;
};
export type QuoteProposalProviderQuery$data = {
  readonly sourceProposal: {
    readonly announcementSetId: string | null;
    readonly id: string;
    readonly integerId: number | null;
    readonly internalDocumentSetId: string | null;
    readonly participationConfirmed: boolean | null;
    readonly quote: {
      readonly id: string;
      readonly integerId: number | null;
      readonly projectName: string | null;
    };
    readonly status: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"Questions_sourceProposal" | "QuoteProposalUserManagementComponent_sourceProposal">;
  } | null;
};
export type QuoteProposalProviderQuery = {
  response: QuoteProposalProviderQuery$data;
  variables: QuoteProposalProviderQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "quoteProposalId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "quoteProposalId",
    "variableName": "quoteProposalId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "integerId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "announcementSetId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "internalDocumentSetId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "participationConfirmed",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QuoteProposalProviderQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "QuoteProposalNode",
        "kind": "LinkedField",
        "name": "sourceProposal",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "QuoteNode",
            "kind": "LinkedField",
            "name": "quote",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "QuoteProposalUserManagementComponent_sourceProposal"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Questions_sourceProposal"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QuoteProposalProviderQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "QuoteProposalNode",
        "kind": "LinkedField",
        "name": "sourceProposal",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "QuoteNode",
            "kind": "LinkedField",
            "name": "quote",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v8/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SourceUserRoleNode",
            "kind": "LinkedField",
            "name": "userRoles",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserNode",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isActive",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "role",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "questionSetId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c1c9ed539e0fa074c0ceaeca1512a8c0",
    "id": null,
    "metadata": {},
    "name": "QuoteProposalProviderQuery",
    "operationKind": "query",
    "text": "query QuoteProposalProviderQuery(\n  $quoteProposalId: ID!\n) {\n  sourceProposal(quoteProposalId: $quoteProposalId) {\n    id\n    integerId\n    announcementSetId\n    internalDocumentSetId\n    status\n    participationConfirmed\n    quote {\n      id\n      integerId\n      projectName\n    }\n    ...QuoteProposalUserManagementComponent_sourceProposal\n    ...Questions_sourceProposal\n  }\n}\n\nfragment Questions_sourceProposal on QuoteProposalNode {\n  id\n  status\n  questionSetId\n  quote {\n    id\n    integerId\n    status\n  }\n}\n\nfragment QuoteProposalUserManagementComponent_sourceProposal on QuoteProposalNode {\n  id\n  userRoles {\n    user {\n      id\n      firstName\n      lastName\n      isActive\n    }\n    email\n    role\n  }\n}\n"
  }
};
})();

(node as any).hash = "0ce5750c107fad19c1ca43b2bff7bca8";

export default node;
