import { Form, Formik } from 'formik';
import React from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';

import Section from 'src/components/sections/section';
import {
    usePatchProjectDetailsAsync,
    useProjectDetails,
    useProjectStep,
} from 'src/project/state/hook';
import { sanitizeProjectDetails } from 'src/project/state/sanitizers';
import useProjectActions from 'src/project/useProjectActions';
import ProjectFooter from 'src/projectBrief/projectFooter';
import DismissibleToast from 'src/utils/DismissibleToast';
import { validateFormValues } from 'src/utils/validateFormValues';

import { ProjectDetailsDefaultvalue } from '../state/projectDetailsSlice';
import ProjectAccompanyingDocuments from './projectAccompanyingDocuments';
import ProjectAdditionalInformation from './projectAdditionalInformation';
import ProjectContactInformationForm from './projectContactInformationForm';
import ProjectSetupForm from './projectSetupForm';
import ProjectTimelineForm from './projectTimelineForm';
import ProposalContentsForm from './proposalContentsForm';
import ProposalValidityForm from './proposalValidityForm';
import { validationSchema } from './validation';

const RfpDetails: React.FC = () => {
    const projectDetails = useProjectDetails();
    const navigate = useNavigate();
    const { nextStep } = useProjectStep();

    const patchProjectDetails = usePatchProjectDetailsAsync();
    const formRef = React.useRef(null);

    const onSave = async () => {
        const payload = { ...formRef.current?.values };
        if (payload.noPersonalContactInformation !== false) {
            payload.quoteContacts = [];
        } else {
            payload.quoteContacts = payload.quoteContacts.map((contact) => ({
                ...contact,
                first_name: contact?.firstName,
                last_name: contact?.lastName,
                job_title: contact?.jobTitle,
                salutation: contact?.salutation,
            }));
        }
        payload.documents = projectDetails?.documents;
        const response = (await patchProjectDetails(payload)) as any;
        if (response.status !== 200) {
            return;
        }

        let sanitizedData = {} as any;
        if (response.payload) {
            sanitizedData = sanitizeProjectDetails(response?.payload);
        }
        formRef.current.resetForm({
            values: {
                ...formRef.current?.values,
                clientIntroduction: sanitizedData?.clientIntroduction,
            },
        });
    };

    const handleValidate = async () => {
        const isValid = await validateFormValues(formRef, validationSchema);
        if (!isValid) {
            toast.error(
                DismissibleToast(
                    t`Your Project Details are not valid. Please see the errors below.`,
                ),
                { duration: 4000 },
            );
            return;
        }
        toast.success(DismissibleToast(t`Your Project Details are valid.`), { duration: 4000 });
    };

    const hanldeNext = () => {
        navigate(nextStep.path);
    };

    const actions = useProjectActions({
        onValidate: handleValidate,
        withChecklist: true,
        onSave,
    });

    return (
        <Section
            title={<Trans>Project Details</Trans>}
            actions={actions}
            data-testid="projectDetails"
        >
            <Formik
                innerRef={formRef}
                onSubmit={() => null}
                validateOnChange={false}
                validateOnBlur
                validationSchema={validationSchema}
                initialValues={{ ...ProjectDetailsDefaultvalue, ...projectDetails }}
            >
                <Form>
                    <ProjectSetupForm />
                    <ProposalContentsForm />
                    <ProposalValidityForm />
                    <ProjectTimelineForm />
                    <ProjectAdditionalInformation />
                    <ProjectContactInformationForm />
                    <ProjectAccompanyingDocuments />
                    <ProjectFooter
                        onValidate={handleValidate}
                        onNext={hanldeNext}
                        onSave={onSave}
                    />
                </Form>
            </Formik>
        </Section>
    );
};

export default RfpDetails;
