import { Field, useFormikContext } from 'formik';
import React from 'react';

import FormControlLabel from '@mui/material/FormControlLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Switch from '@mui/material/Switch';

interface SwitchFieldProps {
    name: string;
    label: React.ReactNode;
    position?: 'end' | 'start';
    disabled?: boolean;
}

interface CustomFormControlLabelProps {
    position: 'end' | 'start';
    checked: boolean;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    label: React.ReactNode;
    field: any;
    disabled?: boolean;
}

const CustomFormControlLabel = ({
    position,
    checked,
    onChange,
    label,
    field,
    ...rest
}: CustomFormControlLabelProps) => (
    <FormControlLabel
        label={label}
        control={
            <Switch
                inputProps={{ 'aria-label': 'controlled' }}
                checked={checked}
                color="primary"
                onChange={onChange}
            />
        }
        labelPlacement={position}
        {...field}
        {...rest}
    />
);

const SwitchField = ({ name, label, position = 'end', ...rest }: SwitchFieldProps) => {
    const { values, setFieldTouched, setFieldValue } = useFormikContext() as any;
    const handleChange = (e) => {
        setFieldTouched(name);
        setFieldValue(name, e.target.checked);
    };
    return (
        <InputAdornment position={position}>
            <Field
                onChange={handleChange}
                component={CustomFormControlLabel}
                position={position}
                name={name}
                checked={!!values[name]}
                label={label}
                {...rest}
            />
        </InputAdornment>
    );
};

export default SwitchField;
