import React from 'react';
import toast from 'react-hot-toast';

import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';

import { useCreateProjectAndPatchProjectInfoAsync } from 'src/project/state/hook';
import DismissibleToast from 'src/utils/DismissibleToast';

import { useFetchRefineObjectives, useFetchSuggestions, useSuggestions } from '../state/hooks';
import {
    BaseValidationSchema,
    ExtendedValidationSchema,
    SuggestionsValidationSchema,
} from '../validation';

const useProjectBriefGeneration = () => {
    const formRef = React.useRef(null);

    const suggestions = useSuggestions();
    const fetchSuggestions = useFetchSuggestions();
    const fetchRefinedObjectives = useFetchRefineObjectives();
    const createAndPatchProject = useCreateProjectAndPatchProjectInfoAsync();
    const validateForm = React.useCallback(async (values, schema) => {
        try {
            await schema.validate(values, { abortEarly: false });
            formRef.current?.setErrors({});
            return {};
        } catch (yupErrors) {
            const formErrors = yupErrors.inner.reduce(
                (errors, yupError) => ({
                    ...errors,
                    [yupError.path]: yupError.message,
                }),
                {},
            );
            formRef.current?.setErrors(formErrors);
            formRef.current?.setTouched(
                Object.keys(formErrors).reduce((acc, key) => ({ ...acc, [key]: true }), {}),
            );

            return formErrors;
        }
    }, []);

    const handleApplyAll = React.useCallback(
        (values, setValues) => {
            const updatedProjectObjectives = [...values.projectObjectives];
            const updatedProjectDeliverables = [...values.projectDeliverables];
            for (let i = 0; i < suggestions.projectObjectives.length; i++) {
                if (suggestions.projectObjectives[i]) {
                    updatedProjectObjectives[i] = suggestions.projectObjectives[i];
                }
            }

            for (let i = 0; i < suggestions.projectDeliverables.length; i++) {
                if (suggestions.projectDeliverables[i]) {
                    updatedProjectDeliverables[i] = suggestions.projectDeliverables[i];
                }
            }

            const updatedValues = {
                ...values,
                projectObjectives: updatedProjectObjectives,
                projectDeliverables: updatedProjectDeliverables,
                initialSituation: suggestions.initialSituation,
                projectApproach: suggestions.projectApproach,
            };
            setValues(updatedValues);
        },
        [
            suggestions.projectDeliverables,
            suggestions.projectObjectives,
            suggestions.projectApproach,
            suggestions.initialSituation,
        ],
    );

    const generateSuggestions = React.useCallback(
        async (values) => {
            const fieldErrors = await validateForm(values, SuggestionsValidationSchema);
            if (Object.keys(fieldErrors).length === 0) {
                fetchSuggestions({
                    ...values,
                    language: i18n.locale,
                });
            } else {
                toast.error(
                    DismissibleToast(
                        t`In order to generate suggestion you need to fill in the missing fields below`,
                    ),
                    { duration: 4000 },
                );
            }
        },
        [fetchSuggestions, validateForm, i18n],
    );

    const refineObjectives = React.useCallback(
        async (values) => {
            const fieldErrors = await validateForm(values, BaseValidationSchema);
            if (Object.keys(fieldErrors).length === 0) {
                fetchRefinedObjectives({
                    ...values,
                    language: i18n.locale,
                });
            } else {
                toast.error(
                    DismissibleToast(
                        t`In order to generate suggestion you need to fill in the missing fields below`,
                    ),
                    { duration: 4000 },
                );
            }
        },
        [fetchRefinedObjectives, validateForm, i18n],
    );

    const validateFormProject = React.useCallback(
        async (values) => {
            const fieldErrors = await validateForm(values, ExtendedValidationSchema);
            if (Object.keys(fieldErrors).length === 0) {
                return true;
            }
            toast.error(
                DismissibleToast(
                    t`In order to create a project you need to fill in the missing fields below.`,
                ),
                { duration: 4000 },
            );
            return false;
        },
        [validateForm],
    );

    const createProjectAction = React.useCallback(async () => {
        const values = formRef?.current?.values;
        const isProjectValid = await validateFormProject(values);
        if (isProjectValid) {
            createAndPatchProject(values);
        }
    }, [validateFormProject, createAndPatchProject]);

    const validateProject = React.useCallback(async () => {
        const values = formRef?.current?.values;
        const fieldErrors = await validateForm(values, ExtendedValidationSchema);
        if (Object.keys(fieldErrors).length === 0) {
            return true;
        }
        return false;
    }, [validateForm]);

    return {
        refineObjectives,
        generateSuggestions,
        createProjectAction,
        handleApplyAll,
        validateProject,
        validateFormProject,
        formRef,
    };
};

export default useProjectBriefGeneration;
