import { put, take, takeEvery } from 'redux-saga/effects';

import { clearContactInformationUser } from 'src/project/state/projectVendorsSlice';
import {
    createProjectVendorContactAsync,
    updateProjectVendorContactAsync,
    updateProjectVendorsAsync,
} from 'src/project/state/thunk';
import { ProjectVendorContactPayload } from 'src/project/types';

import { updateVendorsAction } from './actions';

function* updateVendorContactsSaga(action) {
    const { payload } = action;
    const {
        contact,
        contactInformationUser,
        companies,
        individual,
        id: projectId,
        vendors,
    } = payload;

    //  Update project vendors using the existing_company
    //    incoming_company -> contact.company
    //    existing_company -> contactInformationUser?.company?.id
    //    a. if incoming_company exists in current vendors remove it
    //    b. if existing_company does not exist add it to project vendors and then save
    //    c. if existing_company exists just save and continue
    if (contactInformationUser && contactInformationUser?.company?.id !== contact?.company) {
        const projectVendorIDs = companies;
        const contactCompanyIndex = projectVendorIDs.indexOf(contact.company);
        if (contactCompanyIndex > -1) {
            projectVendorIDs.splice(contactCompanyIndex, 1);
        }
        if (projectVendorIDs.indexOf(contactInformationUser.company.id) === -1) {
            projectVendorIDs.push(contactInformationUser.company.id);
        }

        yield put(updateProjectVendorsAsync({ companies: projectVendorIDs, id: projectId }));
        const { type } = yield take([
            updateProjectVendorsAsync.fulfilled,
            updateProjectVendorsAsync.rejected,
        ]);
        if (type === updateProjectVendorsAsync.rejected) {
            return;
        }
    }
    const contactPayload: ProjectVendorContactPayload = {
        quote: contact.quote,
        company: contactInformationUser?.company?.id ?? contact.company,
        autoinvite: contact.autoinvite,
        individual: {
            first_name: individual.firstName,
            surname: individual.surname,
            email: individual.email,
            phone: individual.phone,
        },
    };

    if (!contact.id) {
        yield put(createProjectVendorContactAsync({ projectId, data: contactPayload, vendors }));
        const { type } = yield take([
            createProjectVendorContactAsync.fulfilled,
            createProjectVendorContactAsync.rejected,
        ]);
        if (type === createProjectVendorContactAsync.rejected) {
            return;
        }
    } else {
        yield put(
            updateProjectVendorContactAsync({
                projectId,
                contactId: contact.id,
                data: contactPayload,
                vendors,
            }),
        );
        const { type } = yield take([
            updateProjectVendorContactAsync.fulfilled,
            updateProjectVendorContactAsync.rejected,
        ]);
        if (type === updateProjectVendorContactAsync.rejected) {
            return;
        }
    }

    yield put(clearContactInformationUser());
}

export function* watchUpdateVendorsAction() {
    yield takeEvery(updateVendorsAction.type, updateVendorContactsSaga);
}
