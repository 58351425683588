import { FC, useState } from 'react';
import { useParams } from 'react-router';

import { Paper, TableBody } from '@mui/material';

import Table from 'src/components/common/table';

import { useProjectVendorsSelector, useUpdateProjectVendorsAsync } from '../state/hook';
import { ProjectVendor } from '../types';
import ContactInformationDrawer from './contactInformationDrawer';
import ExternalVendorForm from './externalVendorForm';
import ProjectNoVendorsSelectedWarning from './projectNoVendorsSelectedWarning';
import VendorsTableHead from './vendorsTableHead';
import VendorsTableRow from './vendorsTableRow';

const VendorsTable: FC = () => {
    const projectVendors = useProjectVendorsSelector();
    const { companies } = projectVendors;
    const updateProjectVendors = useUpdateProjectVendorsAsync();
    const [contactInformationDrawerOpen, setContactInformationDrawerOpen] =
        useState<boolean>(false);
    const [currentVendor, setCurrentVendor] = useState<ProjectVendor>(null);
    const { id } = useParams();
    const defaultContact = {
        id: null,
        quote: parseInt(id, 10),
        company: currentVendor?.id,
        individual: null,
        isVerified: false,
        autoinvite: false,
    };

    const tableRows = (companies || []).map((company) => (
        <VendorsTableRow
            row={company}
            onRemove={() => {
                const idx = companies.indexOf(company);
                const updatedComanies = [...companies];
                updatedComanies.splice(idx, 1);
                updateProjectVendors(updatedComanies.map((c) => c.id));
            }}
            onEdit={() => {
                setContactInformationDrawerOpen(true);
                setCurrentVendor(company);
            }}
        />
    ));

    const handleDrawerClose = () => {
        setContactInformationDrawerOpen(false);
    };

    return (
        <>
            {tableRows.length ? (
                <>
                    <Table component={Paper}>
                        <VendorsTableHead />
                        <TableBody>{tableRows}</TableBody>
                    </Table>
                    <ContactInformationDrawer
                        open={contactInformationDrawerOpen}
                        onClose={handleDrawerClose}
                        contact={currentVendor?.contact || defaultContact}
                        vendor={currentVendor}
                    />
                </>
            ) : (
                <ProjectNoVendorsSelectedWarning />
            )}
            <ExternalVendorForm />
        </>
    );
};

export default VendorsTable;
